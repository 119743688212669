module.exports = [
  "Yukon",
  "British Columbia",
  "Alberta",
  "Newfoundland And Labrador",
  "Quebec",
  "Ontario",
  "Manitoba",
  "Saskatchewan",
  "Nunavut",
  "Northwest Territories",
  "Tennessee",
  "Kentucky",
  "Virginia",
  "North Carolina",
  "South Carolina",
  "Pennsylvania",
  "New York",
  "New Jersey",
  "Delaware",
  "Maryland",
  "West Virginia",
  "Connecticut",
  "Vermont",
  "New Hamshire",
  "Maine",
  "Massachusetts",
  "Road Island",
  "Florida",
  "Texas",
  "Alaska",
  "Hawaii",
  "District of Columbia",
  "Yukon",
  "British",
  "Alberta",
  "Newfoundland And Labrador",
  "Quebec",
  "Ontario",
  "Manitoba",
  "Saskatchewan",
  "Nunavut",
  "Northwest Territories",
  "South Dakota",
  "Nebraska",
  "Wyoming",
  "Colorado",
  "Kansas",
  "Iowa",
  "Utah",
  "Minnesota",
  "Oklahoma",
  "New Mexico",
  "Arizona",
  "California",
  "Nevada",
  "Oregon",
  "Montana",
  "Washington",
  "Idaho",
  "North Dakota",
  "Wisconsin",
  "Illinois",
  "Michigan",
  "Indiana",
  "Ohio",
  "Missouri",
  "Arkansas",
  "Louisiana",
  "Mississippi",
  "Alabama",
  "Georgia US",
  "Georgia_US",
];
